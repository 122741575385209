const Home = (props) => {

    return (
        <div className="home">
            {console.log(props.blaha)}
        </div>
    )
}

export default Home
