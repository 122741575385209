import Home from "./Home";
import {useState} from 'react';

function App() {

  let [x,setX] = useState(10)

  const handleClick = () => {
    x++;
    setX(x);
  }
  
  const handleClick2 = () => {
    x--;
    setX(x);
  }

  const boobies = [
    {name: "eli", age: 38, id: 1},
    {name: "Jenn", age: 32, id: 2},
    {name: "Ari", age: 15, id: 3}
  ]

  return (
    <div className="App">
      <p>Hi!</p>
      <Home blaha="hi fren" />
      <button onClick = {handleClick}>Up</button>
      <button onClick = {handleClick2}>Dn</button>
      <h1>{x}</h1>
      {boobies.map((dis) => (
        <div key={dis.id}>{dis.name} is {dis.age} years old!</div>
      ))}
    </div>
  );
}

export default App;
